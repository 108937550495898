@import "../../bower/normalize-scss/_normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


@font-face {
    font-family: 'Nunito ExtraBoldItalic';
    src: url('../fonts/Nunito-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito ExtraLightItalic';
    src: url('../fonts/Nunito-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito Bold';
    src: url('../fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito ExtraLight';
    src: url('../fonts/Nunito-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito ExtraBold';
    src: url('../fonts/Nunito-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Regular';
    src: url('../fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Italic';
    src: url('../fonts/Nunito-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito BoldItalic';
    src: url('../fonts/Nunito-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito LightItalic';
    src: url('../fonts/Nunito-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito Light';
    src: url('../fonts/Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito SemiBold';
    src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito BlackItalic';
    src: url('../fonts/Nunito-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito Black';
    src: url('../fonts/Nunito-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito SemiBoldItalic';
    src: url('../fonts/Nunito-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro Regular';
    src: url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Italic';
    src: url('../fonts/SourceSansPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro Black';
    src: url('../fonts/SourceSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro ExtraLight';
    src: url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro SemiBold';
    src: url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro SemiBoldItalic';
    src: url('../fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro Light';
    src: url('../fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro BoldItalic';
    src: url('../fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro ExtraLightItalic';
    src: url('../fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro BlackItalic';
    src: url('../fonts/SourceSansPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro LightItalic';
    src: url('../fonts/SourceSansPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

$nunitoLight: 'Nunito Light';
$nunitoReg: 'Nunito Regular';
$nunitoSemiBold: 'Nunito SemiBold';
$nunitoBold: 'Nunito Bold';
$nunitoBlack: 'Nunito Black';
$nunitoLightItalic: 'Nunito LightItalic';
$nunitoRegItalic: 'Nunito Italic';
$nunitoSemiBoldItalic: 'Nunito SemiBoldItalic';
$nunitoBoldItalic: 'Nunito BoldItalic';
$nunitoBlackItalic: 'Nunito BlackItalic';
$nunitoExtraBoldItalic: 'Nunito ExtraBoldItalic';
$sourceLight: 'Source Sans Pro Light';
$sourceReg: 'Source Sans Pro Regular';
$sourceSemiBold: 'Source Sans Pro SemiBold';
$sourceBold: 'Source Sans Pro Bold';
$sourceBlack: 'Source Sans Pro Black';
$sourceLightItalic: 'Source Sans Pro LightItalic';
$sourceRegItalic: 'Source Sans Pro Italic';
$sourceSemiBoldItalic: 'Source Sans Pro SemiBoldItalic';
$sourceBoldItalic: 'Source Sans Pro BoldItalic';
$sourceBlackItalic: 'Source Sans Pro BlackItalic';

$lightBlue: #1c8ac8;
$black: #2c3136;
$blue: #0f4866;
$lightGray: #4f5256;
$gray: #7b7b7b;


$danger: #F44336;
$secondary-color: white;
$main-color: #333;
$secondary-color: #2196f3;
$width: 550px; // Change Me

//fix issue on slick with flex parent
*{
    min-height: 0;
    min-width: 0;
}
$header1: 40px;

html, body {
	font-family: $nunitoReg;
	color: $black;
}

body {
	background-image: url('../img/app/body-bg.png');
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: contain;
	margin: 0;
	padding: 0;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}
ul {
	padding-left: 0;
}
li {
	list-style: none;
}
.clickable {
    cursor: pointer;
}
.nunitoBold {
    font-family: $nunitoBold;
}
.btn-black-outline {
    color: $black;
    border: 1px solid $black;
    text-transform: uppercase;
    min-width: 150px;
    border-radius: 50px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
    height: 40px;
    font-family: $sourceBold;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;
    &:hover {
        background: $black;
        color: #ffffff;
    }
}
.blog {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
    .image-container {
        background-size: cover;
        background-position: center;
        height: 0;
        padding-top: 80%;
    }
    .blog-content {
        display: flex;
        flex-direction: column;
        padding: 30px 60px;
        h6 {
            margin: 0;
            text-align: center;
            color: #205d97;
            font-family: $sourceSemiBold;
            font-size: 12px;
            text-transform: uppercase;
        }
        h3 {
            font-family: $nunitoSemiBold;
            font-size: 20px;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            position: relative;
            margin: 0;
            margin-bottom: 15px;
            &::after {
                content: '';
                max-width: 50px;
                height: 1px;
                width: 100%;
                border-bottom: solid 1px $gray;
                position: absolute;
                bottom: 0;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            
            }
        }
        .description {
            font-size: 12px;
        }
        a {
            align-self: flex-end;
            color: #9b9b9b;
            margin-top: 30px;
            font-size: 14px;
        }
    }
}
.btn-white-outline {
    color: #ffffff;
    border: 1px solid #ffffff;
    text-transform: uppercase;
    background-color: transparent;
    min-width: 150px;
    border-radius: 50px;
    height: 40px;
    font-family: $sourceBold;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;
    &:hover {
        background: #ffffff;
        color: $black;
    }
    &.light-blue {
        border: 1px solid $lightBlue;
        background-color: $lightBlue;
        transition: all .3s ease;
        &:hover {
            opacity: .8;
            color: #fff;
        }
    }
}
.nav-fixed {
    position:fixed;
    width: 100%;
    z-index: 99999;
}
.blog-card {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
    .image-container {
        background-size: cover;
        background-position: center;
        height: 0;
        padding-top: 80%;
    }
    .blog-content {
        display: flex;
        flex-direction: column;
        padding: 30px 60px;
        h6 {
            margin: 0;
            text-align: center;
            color: #205d97;
            font-family: $sourceSemiBold;
            font-size: 12px;
            text-transform: uppercase;
        }
        h3 {
            font-family: $nunitoSemiBold;
            font-size: 20px;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            position: relative;
            margin: 0;
            margin-bottom: 15px;
            &::after {
                content: '';
                max-width: 50px;
                height: 1px;
                width: 100%;
                border-bottom: solid 1px $gray;
                position: absolute;
                bottom: 0;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
            
            }
        }
        .description {
            font-size: 12px;
        }
        a {
            align-self: flex-end;
            color: #9b9b9b;
            margin-top: 30px;
            font-size: 14px;
        }
    }
}
@media screen and (max-width: 991px) {
    .container {
        width: unset;
    }
}
.info-nav {
	background-color: $black;
	padding: 5px 0;
	.info {
		display: flex;
        display: -webkit-flex;
        justify-content: flex-end;
        align-content: center;
        @media screen and (max-width: 500px) {
            flex-direction: column;
        }
		span {
			color: #ffffff;
			font-size: 14px;
			&:first-child {
				margin-right: 20px;
			}
			i {
				padding-right: 10px;
			}
		}
	}
}
.nav-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    cursor: pointer;
    z-index: 1;
    &.open {
        display: block;
    }
}
.navbar-default {
	background-color: rgba(255,255,255,0.7);
	border: none;
	border-radius: 0;
	-webkit-box-shadow: 0px 2px 25px 0px rgba(44,49,54,0.5);
	-moz-box-shadow: 0px 2px 25px 0px rgba(44,49,54,0.5);
    box-shadow: 0px 2px 25px 0px rgba(44,49,54,0.5);
    transition: all .3s ease;
    &.scrolled {
        background-color: #fff;
    }
    .nav-container {
        display: flex;  
        align-items: center;
		justify-content: space-between;
		
		
        .navbar-nav {
            height: 80px;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            li {
                a {
                    color: $lightBlue;
                    text-transform: uppercase;
                    font-family: $sourceReg;
					transition: all .3s ease;
					
					&:hover, &.active{
						opacity: .8;
					}

					&.active {
						font-weight: bold;
						border-bottom: 1px solid $lightBlue;
					}
					
                }
                &:last-child {
                    a {
                        padding-right: 15px; //from zero to 15
                    }
                }
            }
        }
        &.in {
            .navbar-nav {
                display: block!important;
            }
        }
    }
	.navbar-brand {
		height: unset;
		padding: 12px 5px;
        margin-left: 0px !important;
        background: inherit;
		img {
            max-height: 56px;
        }
	}
	
      
    .hamburger {
        width: 30px;
        height: 22.5px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 1;
        display: none;
        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: $gray;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
            &:nth-child(1) {
                top: 0px;
            }
            &:nth-child(2), &:nth-child(3) {
                top: 9px;
            }
            &:nth-child(4) {
                top: 18px;
            }
        }
        &.open {
            span {
                &:nth-child(1) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
                &:nth-child(4) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }

    @media screen and (max-width: 964px) {
        .hamburger {
            display: block;
        }
        .navbar-nav {
            position: fixed;
			height: 100% !important;
            top: 0;
            bottom: 0;
            background: #fff;
            height: 100%;
            margin-top: 27px;
            box-shadow: -3px 3px 26px 1px rgba(0,0,0,0.1);
            text-align: right;
            width: 200px;
            padding-top: 80px;
            right: -500px;
            transition: all .5s ease-in-out;
            display: flex;
            flex-direction: column;
            &.open {
				right: 0px;
            }
        }
    }
}
.container {
    &.container--fullwidth-right {
        margin-right: unset;
        padding-right: 0;
    }
    @media screen and (max-width: 767px) {
        &.container--fullwidth-right {
            margin-right: auto;
            padding-right: 15px;
        }
    }
}

.slick-vertical {
    .slick-slide {
        border: unset;
    }
}
.carousel_wrap { 
	height: auto;
	position:relative;
	.nav_button { 
		position:absolute;
		left:0px;
		top:50%;
		margin-top:-30px;
		height:60px;
		width:30px;
		right:0px;
		font-size:40px;
		font-weight:600;
		color:$black;
		cursor:pointer;
		display:inline-block;
		transition: all .3s ease;
	}
	.nav_button.prev_button {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		font-family: FontAwesome;
		font-weight: 300;
		font-size: 30px; 
		left:50px;
		&:after {
			content: "\f053";
		}
	}
	.nav_button.next_button { 
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		font-family: FontAwesome;
		font-weight: 300;
		font-size: 30px;
		left:auto;
		right:50px;
		&:after {
			content: "\f054";
		}
	}
	#carousel {
		width: 100%;
		margin:0px auto;
		height: 400px;
		position:relative;
		clear:both;
		overflow:hidden;
		> div {
			visibility:hidden;
			cursor:pointer;
			> img {
				opacity:0;
				width: 375px;
				height: 370px;
				@media screen and (max-width: 500px) {
					width: 280px;
				}
			}
			.carousel-container {
				position: absolute;
				background-color:#fff;
				box-shadow: -3px 3px 26px 1px rgba(0,0,0,0.1);
				top: 0px;
				bottom: 0px;
				right: 0;
				left: 0px;
				height:100%;
				width:100%;
				box-sizing:border-box;
				display:block;
				text-align: center;
				padding: 20px 40px;
			}
		}
	}
}
.gallery {
    padding: 111px 0 60px;
    min-height: calc(100vh - 60px);
    .gallery-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        .arrow-container, .scroll-info-container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 99;
            pointer-events: none;
            transition: all 0.3s ease-in-out;
            .icon-scroll {
                width: 40px;
                height: 60px;
                margin-left: 0;
                top: 50%;
                margin-top: -35px;
                box-shadow: inset 0 0 0 3px #fff;
                border-radius: 25px;   
                position: absolute;
                &:before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    background: #fff;
                    top: 13px;
                    border-radius: 4px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    animation: move 3s ease infinite;
                    @keyframes move {
                        0% {
                            top: 13px;
                            background: #fff;
                        }
                        50% {
                            top: 40px;
                            background: #e7e7e7;
                        }
                        100% {
                            top: 13px;
                            background: #fff;
                        }
                    }
                }
            }

        }
        .scroll-info-container {
            pointer-events: all;
            color: #fff;
            z-index: 999;
            background-color: rgba(0,0,0, .5);
            cursor: pointer;
            transition: all .3s ease-in;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
            &.clicked {
                display: none;
            }
        }
        .gallery-items {
            .slick-list {
                outline: none;
            }
            .gallery-item {
                min-height: 600px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                .gallery-info {
                    background: rgba(0,0,0,0.5);
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    pointer-events: none;
                    transition: all .3s ease;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 60px;
                    .info-container {
                        max-width: 80%;
                    }
                    h1 {
                        padding-bottom: 20px;
                    }
                    p {
                        text-align: justify;
					}
					
					@media screen and (max-width: 700px) {
						padding: 30px;
						h1 {
							font-size: 18px;
							margin-top: 10px;
							padding-bottom: 5px;
						}

						p {
							font-size: 14px;
						}
					}

					@media screen and (max-width: 425px) {
						p {
							font-size: 12px;
						}
					}
                }
                &:hover {
                    .gallery-info {
                        opacity: 1;
                    }
                }
            }
        }
        @media screen and (max-width: 700px) {
            .arrow-container, .scroll-info-container {
                .icon-scroll {
                    width: 60px;
                    height: 40px;
                    margin-left: 0;
                    top: 52%;
                    box-shadow: inset 0 0 0 3px #fff;
                    border-radius: 25px;   
                    position: absolute;
                    &:before {
                        content: '';
                        width: 7px;
                        height: 7px;
                        background: #fff;
                        top: 50%;
                        border-radius: 4px;
                        position: absolute;
                        left: 13px;
                        transform: translateY(-50%);
                        animation: move 3s ease infinite;
                        @keyframes move {
                            0% {
                                left: 13px;
                                background: #fff;
                            }
                            50% {
                                left: 42px;
                                background: #e7e7e7;
                            }
                            100% {
                                left: 13px;
                                background: #fff;
                            }
                        }
                    }
                }
            }
            grid-template-columns: 1fr;
            grid-template-rows: 40vh 40vh;
            .gallery-items {
                .gallery-item {
                    padding: 15px;
                    min-height: 40vh;
                    .gallery-info {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .info-container {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.home-page {
    .section-title {
        font-family: $nunitoBold;
        text-transform: uppercase;
        text-align: center;
    }
	.banners {
        .mobile {
            display: none;
        }
        @media screen and (max-width: 768px) {
            .desktop {
                display: none;
            }
            .mobile {
                display: block;
            }
        }
		.banner {
			min-height: 100vh;
            width: 100%;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            // background-position: center !important;
            -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 80%, 45% 100%, 0% 75%, 0% 75%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 80%, 45% 100%, 0% 75%, 0% 75%);
            position: relative;
            a {
                margin-top: 20px;
                max-width: 150px;
            }
            &:after {
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0, .3);
                position: absolute;
                z-index: -1;
            }
        }
        .slick-dots {
            bottom: 25%;
            > li {
                button {
                    background-color: rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                }
            }
            .slick-active {
                button {
                    background-color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
    .qualities {
        padding: 80px 0;
        .bg-image {
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            top: 75vh;
            right: 0;
            left: 0;
            height: 550px;
            z-index: -1;
            transform: rotate(180deg);
        }
        .grid {
            display: grid;
            grid-gap: 50px;
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
            @media screen and (max-width: 400px) {
                grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
            }
            .quality-card {
                .quality {
                    display: flex;
                    display: -webkit-flex;
                    .icon {
                        margin-right: 30px;
                        color: $blue;
                        i {
                            font-size: 25px;
                        }
                    }
                    .text {
                        .title {
                            font-family: $sourceSemiBold;
                            text-transform: uppercase;
                            font-size: 18px;
                            color: $blue;
                            margin-bottom: 10px;
                        }
                        .desc {
                            color: $black;
                            font-family: $nunitoSemiBold;
                        }
                    }
                }
            }
        }
    }
    .services {
        .service-container {
            min-height: 300px;
            width: 400px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            -webkit-box-shadow: 0px 0px 15px 0px rgba(44,49,54,0.7);
            -moz-box-shadow: 0px 0px 15px 0px rgba(44,49,54,0.7);
            box-shadow: 0px 0px 15px 0px rgba(44,49,54,0.7);
            display: flex;
            align-items: flex-end;
            padding: 15px;
            @media screen and (max-width: 500px) {
                width: 280px;
                
            }
            p {
                font-family: $nunitoBold;
                color: #fff;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    top: -8px;
                    left: 1px;
                    width: 30%;
                    height: 2px;
                    background: #fff;
                }
            }
        }
        .container {
            position: relative;
            #prev {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(50%);
            }
            #next {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(50%);
            }
            i {
                font-size: 40px;
                cursor: pointer;
                text-align: center;
                opacity: .8;
                transition: all .3s ease;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .inquiry {
        padding: 0 0 100px;
        text-align: center;
        a {
            max-width: 200px;
            margin: 0 auto;
        }
    }
}

.about-page {
    padding-top: 180px;
    .banner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        min-height: 400px;
        color: #fff;
        border-radius: 50px;
        overflow: hidden;
        border-bottom-right-radius: 0;
        .info {
            font-size: 25px;
            padding: 60px 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1 {
                font-size: 25px;
                margin: 0;
                margin-bottom: 30px;
                font-family: $nunitoBold;
                strong {
                    color: $lightGray;
                    font-family: $nunitoSemiBold;
                }
            }
            h6 {
                font-size: 20px;
                margin: 0;
                font-family: $sourceReg;
                strong {
                    font-family: $sourceBold;
                }
            }
        }
        .image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(33, 141, 202, .4);
            }
        }
    }
    .tabs-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0;
        .navigation {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin: 0;
            border: 0;
            li {
                background-color: #218dca;
                padding: 20px;
                color: #fff;
                text-align: center;
                transition: all .3s ease;
                cursor: pointer;
                a {
                    font-family: $sourceBold;
                    pointer-events: none;
                    text-transform: uppercase;
                }
                &.active, &:hover {
                    background-color: #ededed;
                    color: $lightGray;
                }
            }
        }
    }
    .tabs-content {
        margin-top: 80px;
        margin-bottom: 100px;
        .tab-content {
            // display: grid;
            grid-template-columns: 1fr 1.7fr;
            grid-gap: 60px;
            display: none;
            .content-info {
                color: $lightGray;
                h6 {
                    font-family: $nunitoBold;
                    margin: 0;
                    margin-bottom: 15px;
                    font-size: 15px;
                    text-transform: uppercase;
                }
                h1 {
                    font-size: 30px;
                    font-family: $sourceBold;
                    margin: 0;
                    margin-bottom: 40px;
                }
                p {
                    font-size: 15px;
                    color: $black;
                    font-family: $sourceReg;
                    line-height: 30px;
                }
                ul {
                    padding: 20px;
                    > li {
                        list-style-type: disc;
                        color: #222;
                        &:hover {
                            cursor: default;
                        }
                    }
                }
                ol {
                    padding: 20px;
                    > li {
                        list-style-type: decimal;
                        color: #222;
                        &:hover {
                            cursor: default;
                        }
                    }
                }
            }
            .content-images {
                overflow: hidden;
                .main-slider-container {
                    position: relative;
                    .arrow-container {
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        left: 50%;
                        display: grid;
                        color: #fff;
                        grid-template-columns: auto auto;
                        grid-gap: 20px;

                        .arrow {
                            font-size: 30px;
                            opacity: .8;
                            transition: all .3s ease;
                            &:hover {
                                opacity: 1;
                            }
                            i {
                                cursor: pointer;
                            }
                        }
                    }
                    @media screen and (max-width: 767px) {
                        .arrow-container {
                            left: 0;
                            right: 0;
                            padding: 10px;
                            bottom: 50%;
                            transform: translateY(50%);
                            .arrow {
                                &.right {
                                    justify-self: end;
                                }
                            }
                        }
                    }
                }
                .main-slider {
                    .main-content {
                        height: 0;
                        padding-top: 20%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
                .nav-slide {
                    .slick-slide {
                        margin: 0 10px;
                    }
                    .slick-list {
                        margin: 0 -10px;
                    }
                    margin-top: 40px;
                    .slide-thumbnail {
                        height: 0;
                        padding-top: 15%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        position: relative;
                        cursor: pointer;
                        &::after {
                            content: '';
                            position: absolute;
                            background-color: #fff;
                            opacity: .3;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            transition: all .3s ease-in;
                        }
                        &:hover {
                            &:after {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
            &.active {
                display: grid;
            }
        }
    }
    @media screen and (max-width: 1091px) {
        .banner {
            .info {
                padding: 60px 20px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .tabs-content {
            .tab-content {
                grid-gap: 20px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .banner {
            border-bottom-left-radius: 0;
        }
        .tabs-container {
            grid-template-columns: 1fr;
        }
        .tabs-content {
            margin: 40px 0 50px;
            .tab-content {
                grid-template-columns: 1fr;
                grid-gap: 30px;
                .content-info {
                    grid-column: 1;
                    grid-row: 2;
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        .banner {
            min-height: unset;
            position: relative;
            grid-template-columns: 1fr;
            .image {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                &:before {
                    background-color: rgba(33, 141, 202, .9);
                }
            }
            .info {
                background: unset !important;
                padding: 60px;
                z-index: 1;
            }
        }
    }
    @media screen and (max-width: 700px) {
        .banner {
            .info {
                padding: 60px 15px;
            }
        }
    }
}
.corporate-page {
    padding-top: 111px;
    .corporate {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        min-height: calc(100vh - 171px);
        // &:before {
        //     content: '';
        //     position: absolute;
        //     background-color: rgba(33, 141, 202, 0.4);
        //     top: 0;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     z-index: 1;
        // }
        .corporate-page-container {
            background: #fff;
            padding: 60px 50px;
            margin: 50px 0;
            border-radius: 20px;
            box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
            display: grid;
            grid-template-columns: auto auto 1fr;
            grid-gap: 40px;
            height: calc(100vh - 288px);
            z-index: 1;
            position: relative;
            .title {
                font-family: $sourceBold;
                padding-bottom: 40px;
                color: $lightGray;
            }
            p {
                font-family: $sourceReg;
            }
        }
        .line {
            width: 1px;
            background-color: #dedede;
        }
        .corporate-content {
            padding: 15px 0;
            overflow-y: auto;
            &::-webkit-scrollbar-track
            {
                border-radius: 10px;
                background-color: #F5F5F5;
            }
            
            &::-webkit-scrollbar
            {
                width: 8px;
                background-color: #F5F5F5;
            }
            
            &::-webkit-scrollbar-thumb
            {
                border-radius: 10px;
                background-color: #c1c1c1;
			}
			.description {
                ul, ol {
                    margin: 0;
                    padding: 15px 0;
                    li {
                        font-family: $nunitoSemiBold;
                        font-size: 14px;
                        color: #222;
                        margin-bottom: 25px;
                        cursor: default;
                    }
                }
            }
			.question-container {
				margin-bottom: 10px;
				border: 1px solid #c1c1c1;
				border-radius: 5px;
				.question-header {
					border-bottom: 1px solid #c1c1c1;
					cursor: pointer;
					padding: 15px 10px;
					font-size: 16px;
					background-color: $lightBlue;
					color: white;
				}

				.question-answer {
					padding: 15px 20px;
                    ul {
                        padding: 20px;
                        > li {
                            list-style-type: disc;
                            color: #222;
                            &:hover {
                                cursor: default;
                            }
                        }
                    }
                    ol {
                        padding: 20px;
                        > li {
                            list-style-type: decimal;
                            color: #222;
                            &:hover {
                                cursor: default;
                            }
                        }
                    }
				}
			}
        }
        ul, ol {
            margin: 0;
            padding: 15px 0;
            li {
                font-family: $nunitoSemiBold;
                font-size: 14px;
                color: #bdbdbd;
                transition: all .3s ease;
                margin-bottom: 25px;
                cursor: pointer;
                &.active, &:hover {
                    font-family: $nunitoBold;
                    color: $lightGray;
                }
            }
        }
        @media screen and (max-width: 659px) {
            .corporate-page-container {
                grid-template-columns: 1fr;
                grid-gap: 20px;
                padding: 30px 25px;
                height: calc(100vh - 298px);
                .line {
                    width: 100%;
                    height: 1px;
                }
                ul {
                    li {
						text-align: center;
						margin: 0;
						outline: none !important;
						font-size: 18px;
					}
					
					.slick-prev {
						color: $lightBlue;
						transition: .2s;
						left: 25px;
						z-index: 10;

						&:before {
							content: '';
						}

						i {
							font-size: 20px;
						}

						&:hover {
							color: $blue;
						}
					}
					
					.slick-next {
						color: $lightBlue;
						transition: .2s;
						right: 25px;
						z-index: 10;

						&:before {
							content: '';
						}

						i {
							font-size: 20px;
						}

						&:hover {
							color: $blue;
						}
					}
                }
            }
        }
	}
	
	@media screen and (max-width: 500px) {
		padding-top: 130px;
	}
}
.service-card {
    box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
    min-height: 334px;
    padding-bottom: 10px;
    .image-container {
        height: 0;
        padding-top: 80%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    h3 {
        font-size: 18px;
        margin: 0;
        padding: 22px 30px 15px;
        font-family: $nunitoBold;
    }
    p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        font-size: 12px;
        margin: 0 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 50px;
    }
}
.services-page {
    padding-top: 126px;
    .view-service {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: 767px) {
            grid-template-columns: 1fr;
        }
        .service-info {
            padding: 120px 100px;
            background-color: rgba(21, 71, 126, .8);
            color: #fff;

            h1 {
                margin: 0;
                margin-bottom: 20px;
                font-family: $nunitoBold;
            }
            p {
                font-size: 15px;
                font-family: $sourceReg;
                margin: 0;
                padding: 0 0 10px;
            }
            .btn-white-outline {
                display: inline-grid;
                grid-template-columns: auto auto;
                grid-gap: 10px;
                margin-top: 30px;
            }
            @media screen and (max-width: 991px) {
                padding: 60px;
            }
            @media screen and (max-width: 767px) {
                padding: 60px 50px;
                h1 {
                    font-size: 30px;
                }
            }
        }
    }
    .services-slick {
        padding: 80px 40px;
        .slick-track {
            padding: 20px 0;
        }
        /* the slides */
        .slick-slide {
            margin: 0 15px;
        }
        /* the parent */
        .slick-list { 
            margin: 0 -15px;
        }
    }

    .slick-next {
        right: 0;
        color: black;
        z-index: 5;
        &:before {
            font-size: 40px;
            color: black;
            font-family: 'Font Awesome 5 Pro';
            content: '\f105';
        }
    }
    .slick-prev {
        left: 0;
        color: black;
        z-index: 5;
        &:before {
            font-size: 40px;
            color: black;
            font-family: 'Font Awesome 5 Pro';
            content: '\f104';
        }
    }
    .services-main-slick {
        .slick-prev, .slick-next {
            &::before {
                color: #fff;
            }
            
        }
        .slick-prev {
            left: 10px;
        }
        .slick-next {
            right: 10px
        }
	}
	
	@media screen and (max-width: 500px) {
		padding-top: 160px;
	}
}
.gallery-page {
    .testimony-container {
        max-width: 600px;
        background: #fff;
        margin: auto;
        margin-bottom: 70px;
        border-radius: 20px;
        .light-blue {
            &:hover {
                opacity: 1;
            }
        }
        .slick-next {
            right: 15px;
            color: $gray;
            &:before {
                font-family: 'Font Awesome 5 Pro';
                color: $gray;
                content: '\f105';
                font-size: 25px;
            }
        }
        .slick-prev {
            left: 15px;
            color: $gray;
            &:before {
                font-family: 'Font Awesome 5 Pro';
                color: $gray;
                content: '\f104';
                font-size: 25px;
            }
        }
        .button-container {
            display: flex;
            .btn-white-outline {
                display: inline-flex;
                padding: 20px;
                margin: auto;
                position: relative;
                top: 20px;
                z-index: 1;
            }
        }

        .testimony {
            display: grid;
            grid-template-columns: 80px 300px;
            justify-content: center;
            align-items: center;
            grid-gap: 20px;
            padding: 40px;
            border-radius: 20px;
            .image {
                width: 80px;
                height: 80px;
                background-position: center;
                border-radius: 100%;
                background-size: cover;
            }
            .text {
                p {
                    font-family: $nunitoReg;
                    font-size: 24px;
                }
                h6 {
                    font-family: $nunitoExtraBoldItalic;
                    font-size: 18px;
                }
            }
            .description {
                grid-column: 1/3;
                font-size: 15px;
                font-family: $sourceReg;
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 700px) {
        .testimony-container{
            .testimony {
                grid-template-columns: 80px auto;
                // padding: 40px 15px;
                grid-gap: 15px;
            }
        }
	}
	
	@media screen and (max-width: 500px) {
		.gallery {
			padding-top: 130px;
		}
	}
}

.blogs-page {
    padding: 111px 0 80px;
    @media screen and (max-width: 767px) {
        padding-bottom: 50px;
	}
	
    .featured-blog-slick {
        .featured-blog {
            min-height: 50vh;
            padding: 40px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-size: cover;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background-color: rgba(0, 0, 0, .5);
            }
            .container {
                max-width: 800px;
                z-index: 1;
                margin-bottom: 60px;
                h1 {
                    font-size: 36px;
                    font-family: $nunitoSemiBold;
                }
                p {
                    font-family: $sourceReg;
                    font-size: 16px;
                }
                a {
                    font-size: 14px;
                    margin-top: 30px;
                    display: inline-block;
                    transition: all .3s ease;
                    &:hover {
                        opacity: .8;
                    }
                }
            }
            @media screen and (max-width: 991px) {
                padding: 0 15px;
            }

        }
        .slick-dots {
            bottom: 25px;
            li {
                width: 10px;
                height: 10px;
                margin: 0 10px 0 0;
                border: solid 1px #fff;
                background-color: transparent;
                border-radius: 100%;
                overflow: hidden;
                transition: all .3s ease;
                &:hover, &.slick-active {
                    background-color: #fff;
                }
                button {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    &:before {
                        content: unset;
                    }
                }
            }
        }
        .slick-next {
            right: 60px;
            color: #fff;
            z-index: 1;
            transition: all .3s ease;
            &:before {
                font-family: 'Font Awesome 5 Pro';
                content: '\f105';
                font-size: 30px;
            }
        }
        .slick-prev {
            left: 60px;
            color: #fff;
            z-index: 1;
            transition: all .3s ease;

            &:before {
                font-family: 'Font Awesome 5 Pro';
                content: '\f104';
                font-size: 30px;
            }
        }
        @media screen and (max-width: 991px) {
            .slick-next {
                right: 30px;
            }
            .slick-prev {
                left: 30px;
            }
        }

    }
    .blog-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 30px 20px;
        margin: 60px 0;
    }
    .load-more-container {
        display: flex;
        .load-more {
            display: inline-flex;
            margin: auto;
            cursor: pointer;
            span {
                display: inline-block;
            }
            i {
                display: none;
            }
            &.fetching {
                i {
                    display: inline-block;
                }
                span {
                    display: none;
                }
            }
        }
	}
	
	@media screen and (max-width: 500px) {
		padding-top: 130px;
	}
}
.blog-view {
    padding-top: 111px;
    .blog-banner {
        min-height: 50vh;
        background-position: center;
        background-size: cover;
        position: relative;
        .prev,
        .next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            outline: none;
            color: #fff;
            text-shadow: 1px 1px #222;
            font-size: 20px;
        }
        .prev {
            left: 0;
        }
        .next {
            right: 0;
        }
        .blog-icon-container {
            display: flex;
            position: absolute;
            background: transparent;
            bottom: -85px;
            left: 0;
            right: 0;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: $lightBlue;
            .blog-icon {
                height: 90px;
                width: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 100%;
                i {
                    font-size: 30px;
                }
            }
            > span {
                font-size: 20px;
                margin-top: 12px;
                border-bottom: solid 1px $lightBlue;
                font-family: $sourceBold;
            }
        }
    }
    .blog-container {
		margin-top: 110px;
		&.service {
			margin-top: 50px;
		}
        .heading {
            padding-bottom: 30px;
            > h1 {
                margin: 0;
                margin-bottom: 20px;
                font-family: $sourceSemiBold;
            }
            .other-info {
                display: inline-grid;
                grid-template-columns: auto auto;
                grid-gap: 20px;
                color: #959595;
                font-size: 12px;
            }
        }
        .blog-content {
            display: grid;
            grid-template-columns: auto 1fr;
			grid-gap: 60px;
			&.service {
				grid-template-columns: 1fr;
			}
            .social-media-container {
                display: flex;
                flex-direction: column;
                margin: 0;
                li {
                    &:first-child {
                        padding-top: 0;
                    }
                    color: $black;
                    text-align: center;
                    font-size: 16px;
                    padding: 10px 20px;
                    font-family: $sourceBold;
                }
            }
            .content {
                padding-bottom: 30px;
                margin-bottom: 60px;
                border-bottom: solid 1px #959595;
                p {
                    font-size: 16px;
                }
                img {
                    max-width: 100%;
                }
            }
        }
        .blog-card {
            .blog-content {
                display: flex;
            }
        }
        .blog-list-slick {
            padding: 50px 30px 60px;
            .slick-track {
                padding: 20px 0;
            }
            .slick-slide {
                margin: 0 15px;
            }
            .slick-list { 
                margin: 0 -15px;
            }
            .slick-next {
                right: -10px;
                color: black;
                z-index: 2;
                &:before {
                    font-size: 40px;
                    color: black;
                    font-family: 'Font Awesome 5 Pro';
                    content: '\f105';
                }
            }
            .slick-prev {
                left: -10px;
                color: black;
                z-index: 2;
                &:before {
                    font-size: 40px;
                    color: black;
                    font-family: 'Font Awesome 5 Pro';
                    content: '\f104';
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .blog-container {
            .blog-content {
                grid-template-columns: 1fr;
                grid-gap: 30px;
                padding: 0;
                .social-media-container {
					display: grid;
					grid-template-areas: 'share share share' 'fb twitter email';
                    // flex-direction: row;
                    grid-row-start: 2;
                    border-bottom: solid 1px #959595;
                    padding-bottom: 30px;
                    margin-bottom: 60px;
                    // li {
                    //     &:first-child {
                    //         padding-top: 10px;
                    //         padding-left: 0;
                    //     }
					// }
					
					.share {
						grid-area: share;
					}
					
					.fb {
						grid-area: fb;
					}
					
					.twitter {
						grid-area: twitter;
					}
					
					.email {
						grid-area: email;
					}
                }
                .content {
                    margin-bottom: 0;
                    border: 0;
                }
            }
        }
	}
	
	@media screen and (max-width: 500px) {
		padding-top: 160px;
    }
    
    table {
        width: 100%;
        tr {
            td {
                padding: 15px;
                border: 1px solid gainsboro;
            }
        }
    }
}
//meet our team
.section {
	.section-header {
		&-title {
            font-size: 36px;
            line-height: 36px;
			font-family: $nunitoSemiBold;
			color: #333333;
					
			@media screen and (max-width: 767px) {
				font-size: 30px;
			}
        }
        &-side {
            .container {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 60px 30px;
                padding: 80px 15px;
                @media screen and (max-width: 767px) {
                    grid-template-columns: 1fr;
                    grid-gap: 15px;
                    padding: 40px 15px;
                }
                &:after, &:before {
                    content: unset;
                }
            }
        }

		&-description {
			font-size: 16px;
			font-family: $sourceReg;
			color: #adadad;
		    line-height: 30px;
		}
	}

	&.meet-our-team-section {
		background-image: url('../img/app/our-team.jpg');
		background-repeat: no-repeat;
		background-position: bottom center;
		background-size: cover;
		min-height: 500px;
		padding: 180px 0px 60px;

		.section-content {

			.slick-list {
				padding: 20px 10px;
				
				@media screen and (max-width: 768px) {
					padding: 0px 10px;
				}
			}

			.our-team-list {
                margin-top: 10px;
				margin-bottom: 30px;
					
				@media screen and (max-width: 991px) {
					margin-top: 50px;

					.slick-prev {
						left: 0px;
					}

					.slick-next {
						right: 0px;
					}
				}
				
				@media screen and (max-width: 768px) {
					margin-top: 30px;
				}

				.slick-slide {
					outline: none;
				}

				.slick-prev, .slick-next {
					&:before {
						content: '';
					}

					opacity: 0.5;
					transition: .2s;
					z-index: 3;

					i {
						color: #353a3e;
						font-size: 30px;
					}

					&:hover {
						opacity: 1;
					}
				}
				

				.our-team-card {
					max-width: 300px;
					margin: 0 auto;
					&-header-container {
						height: 186px;
						position: relative;
						
						@media screen and (max-width: 768px) {
							height: 130px;
						}

					}
					&-header {
						top: 50%;
						left: 0;
						right: 0;
						position: absolute;
						height: 186px;
						width: 186px;
						border-radius: 100%;
						border: 3px solid #fff;
						margin: 0 auto;
                        background-position: center;
                        background-size: cover;
						@media screen and (max-width: 768px) {
							height: 130px;
							width: 130px;
							border: 1px solid #fff;
						}
					}
					
					&-content {
						background: white;
						min-height: 330px;
						-webkit-box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
						-moz-box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
						box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
						border-radius: 15px;
						
						.text {
							padding: 111px 38px 50px;
							text-align: center;
							.name {
								font-family: $nunitoSemiBold;
								font-size: 18px;
								color: #585858;
								margin-bottom: 5px;
							}
							.job {
								text-transform: uppercase;
								font-size: 18px;
								color: #adadad;
								font-family: $sourceBlack;
								margin-bottom: 10px;
								letter-spacing: 1px;
							}
							.job-description {		
								font-size: 14px;	
								color: #adadad;
								font-family: $sourceReg;
							}
						
							@media screen and (max-width: 768px) {
								padding: 70px 10px 20px;

								.name {
									font-size: 20px;
								}

								.job {
									font-size: 14px;
								}

								.job-description {
									font-size: 14px;
								}
							}
						}

						@media screen and (max-width: 768px) {											
							min-height: 250px;
						}
					}

					
					@media screen and (max-width: 768px) {
						max-width: 250px;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
            padding: 140px 0px 20px;
		}

		@media screen and (max-width: 500px) {
			padding-top: 180px;
		}
	}

	&.explore-jobs-section {
		min-height: 500px;
		padding: 60px 0px;

		.jobs-list {
			margin-top: 70px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 20px;
			.job-card {
				background-color: white;
				max-width: 380px;
				margin: 0 auto;
				padding: 40px 25px;
				-webkit-box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
				-moz-box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
				box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.1);
				border-radius: 15px;
				transition: .2s;

				&-title {
					margin-bottom: 10px;
					font-family: $nunitoSemiBold;
					font-size: 24px;
					color: #248fcb;
					text-transform: capitalize;
					// transition: .2s;
					
					span {
						position: relative;
						&:after {
							content: '';
							transition: .2s;
							border-bottom: 1px solid #248fcb;
							position: absolute;
							width: 0%;
							height: 100%;
							left: 0;
							bottom: 0;
						}
					}

					
					@media screen and (max-width: 767px) {
						font-size: 20px;
					}
				}

				&-description {
					font-size: 17px;
					font-family: $sourceReg;
					
					
					@media screen and (max-width: 767px) {
						font-size: 14px;
					}
				}

				&-dates {
					font-size: 15px;
					font-family: $sourceReg;

					@media screen and (max-width: 767px) {
						font-size: 12px;
					}
				}

				&:hover {
					cursor: pointer;
					-webkit-box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.1);
					-moz-box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.1);
					box-shadow: 0px 2px 15px 5px rgba(0, 0, 0, 0.1);

					.job-card-title {
						span {
							&:after {
								width: 100%;
							}
						}
					}
				}
			}

			@media screen and (max-width: 991px) {
				grid-template-columns: 1fr 1fr;
				padding: 25px 10px;
			}

			@media screen and (max-width: 768px) {
				margin-top: 30px;
			}

			@media screen and (max-width: 600px) {
				grid-template-columns: 1fr;
			}

		}

		@media screen and (max-width: 768px) {
			padding: 30px 0px;
		}
	}

	&.contact-section {
		.contact-form-container {
			padding: 30px 50px; 
			display: grid;
			grid-template-columns: 70px 1fr;
            height: 100%;
            align-items: center;
			.icon-container {
				position: relative;

				.address-icon {
					width: 2px;
					height: 70px;
					position: absolute;
					top: 30px;
					left: -30px;
					right: 0;
					margin: 0;
					border: 1px dashed #898989; 
					
					&:before {
						content: ' ';
						position: absolute;
						width: 15px;
						height: 15px;
						background: #1c8ac8;
						top: -5px;
						border-radius: 100%;
						left: -7px;
					}

					&:after {
						content: '';
						position: absolute;
						width: 15px;
						height: 15px;
						background: #898989;
						bottom: -5px;
						border-radius: 100%;
						left: -7px;
					}
				}
			}
			.contact-form {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-areas: 'steel steel steel steel steel steel'
                                    'dimension dimension dimension dimension dimension dimension'
                                    'choice choice choice choice choice choice'
									'pickup pickup pickup pickup pickup pickup'
									'dropoff dropoff dropoff dropoff dropoff dropoff'
									'name name name contact contact contact'
									'email email email . . .'
                                    'message message message message message message'
                                    'submit submit submit submit submit submit';
				grid-gap: 20px;

				.dimension {
					grid-area: dimension;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-column-gap: 10px;
                }
                .steel {
                    grid-area: steel;
                }
				.choice {
					grid-area: choice;
				}
				.pickup {
                    overflow: initial;
					grid-area: pickup;
				}
				.dropoff {
					grid-area: dropoff;
				}
				.name {
					grid-area: name;
				}
				.contact {
					grid-area: contact;
				}
				.email {
					grid-area: email;
				}
				.message {
					grid-area: message;

					textarea {
						resize: none;
						height: 100px;
						background: transparent;
						border: 2px solid #282828;
						color: #282828;

						
					}
					
					&.error {
						color: $danger;

						label {
							color: $danger;
						}

						textarea {
							border-color: $danger;
						}

					}
				}
				.submit {
					grid-area: submit;

					.btn-container {
						text-align: center;
						.btn {
							padding: 13px 25px;
							background-color: #1c8ac8;
							font-weight: bold;
							border: 0;
							border-radius: 25px;
							outline: none !important;
						}
					}
				}
			}

			@media screen and (max-width: 1200px) {
				padding: 30px 50px;
			}

			@media screen and (max-width: 991px) {
				padding: 30px;
				grid-template-columns: 50px 1fr;
				.contact-form {
					grid-template-columns: 1fr;
                    grid-template-areas: 'steel' 
                                        'dimension'
										'choice'
										'pickup'
										'dropoff'
										'name'
										'contact'
										'email'
										'message'
										'submit';
				}
			}

			@media screen and (max-width: 600px) {
				grid-template-columns: 1fr;
				
				.icon-container {
					display: none;
				}
			}
		}
	}
}

.contact-page {
	padding-top: 111px;
    @media screen and (max-width: 500px) {
        padding-top: 111px;
    }
	.contact-page-container {
		display: grid;
		grid-template-columns: 1fr 1fr;	
        max-width: 2500px;
        margin: auto;
        min-height: calc(100vh - 187px);
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
			
			.gmap-section {
				display: none;
			}
		}
	}

	.mdc-input-group {
		
		width: 100%;
		height: 48px;
		overflow: hidden;
		position: relative;
		.mdc-input {
			background: transparent;
			display: block;
			height: 45px;
			width: 100%;
			padding-top: 20px;
			border: none;
			border-radius: 0; // For iOS
			// border-bottom: solid 350 rgba(white, .5);
			color: #282828;
			font-size: 14px;
			transition: .3s ease;

			&:focus, &:not(:placeholder-shown) {
				outline: none;
				// border-bottom-color: $secondary-color;
				~label {
                    top: 0;
                    left: 0;
					font-size: 14px;
					// font: 700 $width/25 Roboto;
					// color: red;
				}


				~ .bar:before {
					transform: translateX(0);
				}
			}
			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0px 1000px transparent inset;
				-webkit-text-fill-color: #282828 !important;

				// border-bottom-color: rgba(white, .5);
			}

			&.disabled {
				cursor: not-allowed;
				color: $gray;

				+ label {
					cursor: not-allowed;
					color: $gray;

					+ .bar {
						background: $gray;
					}
				}

			}

			&.error {
				color: $danger;

				+ label {
					color: $danger;

					+ .bar {
						background: $danger;
					}
				}

			}
			
		}

		.bar {
			// background: $secondary-color;
			background: #282828;
			content: '';
			width: 100%;
			// height: $width/100;
			height: 2px;
			// transform: translateX(-100%);
			transition: .3s ease;
			// margin-top: -2px;
			//
			position: relative;
			&:before {
				content: '';
				position: absolute;
				width: 100%;
				background: #282828;
				transform: translateX(-100%);

            }
		}

		label {
			position: absolute;
			top: 20px;
			color: #282828;
			font-size: 14px;
			cursor: text;
			transition: .25s ease;
            text-transform: capitalize;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
		}
	}
}

footer {
    position: relative;
    background-color: #000;
    .copyright {
        width: 100%;
        bottom: 0;
        padding: 20px 0;
        color: #ffffff;
        .text {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            grid-gap: 30px;
            font-size: 12px;

            span {
                &:last-child {
                    text-align: right;
                }
            }
            .ts {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 20px;
                    margin-left: 10px;
                }
            }

            .social-media-container {
                margin: 0;
                display: flex;
                justify-content: flex-end;
                li {
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        transition: all .3s ease;
                        &:hover {
                            opacity: .8;
                        }
                    }
                }
            }
            @media (max-width: 767px) {
				grid-template-columns: 1fr;
				grid-gap: 5px;

                span {
					text-align: center
				}				
				.social-media-container {
					justify-content: center;
				}
            }
        }
    }  
    &.big {
        min-height: 85vh;
        background-image: url('../img/app/footer-bg.jpg');
        background-position:  center bottom;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-clip-path: polygon(56% 0, 100% 15%, 100% 100%, 0 100%, 0 15%);
        clip-path: polygon(56% 0, 100% 15%, 100% 100%, 0 100%, 0 15%);   
        .copyright {
            position: absolute;
        }
        .text {
            .ts {
                justify-content: center;
            }
        }
    }
}

.construction-page {
    overflow: hidden;
    height: 100vh;
    .banner {
        height: 100vh;
        width: 100%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        .caption {
            display: flex;
            flex-direction: column;
            height: 100vh;
            align-items: center;
            justify-items: center;
            justify-content: center;
            color: #ffffff;
            margin: 0 30px;
            
            .overlay {
                padding: 50px;
                background: rgba(0,0,0,0.7);
            }
            h1 {
                margin: 0 auto;
                font-size: 5em;
                line-height: 1;
                text-align: center;
                @media (max-width: 680px) {
                    font-size: 3.5em;
                }
                @media (max-width: 500px) {
                    font-size: 3em;
                }
                @media (max-width: 340px) {
                    font-size: 2em;
                }
            }
            h3 {
                margin-bottom: 5px;
            }
            ul {
                margin-top: 5px;
                li {
                    font-size: 15px;
                }
            }
           
        }
    }
}
.banners {
    .overlay {
		background: rgba(0,0,0,0.1);
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
    }
    .caption {
        height: 85vh;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        align-content: center;
        color: #ffffff;
        width: calc(100% - 40px);
        margin: auto;
        .name {
            font-family: $nunitoSemiBold;
            font-size: 18px;
            margin: 0;
            padding-bottom: 20px;
            text-transform: uppercase;
        }
        p {
            margin: 0;
        }
        h1, h2, h3, h4, h5 {
            margin: 0;
            padding-bottom: 22px;
            font-family: $nunitoSemiBold;
            strong {
                font-family: $nunitoBlack;
            }
        }
    }
    .slick-slider {
        .slick-prev, .slick-next {
            position: absolute;
            color: #ffffff;
            top: 55%;
            z-index: 9;
            padding: 0;
            background: transparent;
            border: none;
            outline: none;
            font-size: 20px;
        }
        button {
            &:before {
                content: '';
            }
        }
        .slick-prev {
            left: 15px;
        }
        .slick-next {
            right: 15px;
        }
    }
}


[data-notify="container"] {
	border-radius: 0;
	bottom: 0 !important;
	right: 0 !important;
	color: #fff;
	background-color: $lightBlue;
	border: none;
	z-index: 99999 !important;
}

.corporate-page, .corporate:before, .contact-page {
    min-height: 93.7vh;
}

.select2 {
    padding-top: 20px;
    height: 46px;
    width: 100% !important;
}
.select2-container--default {
    .select2-selection--single {
        border: none;
        .select2-selection__arrow {
            top: 21px
        }
    }
}

.grecaptcha-badge, .g-recaptcha {
	display: none !important;
}